@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.paragraphs-item-blurbs {

  .ds-entities {
    @include breakpoint($desktop) {
      display: flex;
    }
    justify-content: center;
  }

  .entity-blurbs {
    @include breakpoint($desktop) {
      flex: 1 0 0;
      padding: 0 20px;
      min-height: 1px;
    }
    max-width: 320px;

    img {
      width: 100%;
    }
  }

  .entity-blurbs:first-child {
    padding-left: 0;
  }
  .entity-blurbs:last-child {
    padding-right: 0;
  }
  .ds-bottom {
    padding-top: 20px;
    h2 {
      font-size: 22px;
      font-weight: 700;
      padding-bottom: 20px;

      @include breakpoint($desktop) {
        padding-bottom: 30px;
      }
    }
  }
  .field-name-field-body {
    padding-bottom: 20px;

    @include breakpoint($desktop) {
      padding-bottom: 34px;
    }
  }
  p, .field-name-field-link {
    @include breakpoint($desktop) {
      font-size: 20px;
    }
  }
  .field-name-field-link {
    margin-bottom: 40px;
    @include breakpoint($desktop) {
      margin-bottom: 0;
    }
  }
}
